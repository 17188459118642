<template>
  <div v-if="isAdminOrMerchant" class="balance-page" :class="{ 'dark-theme': isDarkTheme }">
    <div v-if="isLoading" class="loading-container">
      <i class="pi pi-spin pi-spinner loading-spinner"></i>
      <p class="loading-text">Loading balances...</p>
    </div>

    <div v-else-if="balances.length === 0" class="empty-state">
      <i class="pi pi-wallet empty-icon"></i>
      <h3>No Balance Information Available</h3>
      <p>There are no active payment providers with balance information or you don't have sufficient permissions.</p>
    </div>

    <div v-else class="balance-content">
      <!-- Balance Summary Card -->
      <div class="info-card summary-card">
        <div class="card-header">
          <div class="header-icon-container">
            <i class="pi pi-money-bill header-icon"></i>
          </div>
          <div>
            <h3>Balance Overview</h3>
            <p class="card-subtitle">Summary of all your payment provider balances</p>
          </div>
        </div>
        <div class="card-content">
          <div class="balance-summary-grid">
            <div class="summary-item total-balance">
              <div class="summary-icon-wrapper">
                <i class="pi pi-wallet"></i>
              </div>
              <div class="summary-data">
                <h4>Total Balance</h4>
                <div class="summary-amount">{{ formatTotalAmount() }}</div>
              </div>
            </div>

            <div class="summary-item last-updated">
              <div class="summary-icon-wrapper">
                <i class="pi pi-clock"></i>
              </div>
              <div class="summary-data">
                <h4>Last Updated</h4>
                <div class="summary-date">{{ formatLastUpdated() }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Individual Provider Balances -->
      <div class="info-card providers-card">
        <div class="card-header">
          <div class="header-icon-container">
            <i class="pi pi-list header-icon"></i>
          </div>
          <div>
            <h3>Provider Balances</h3>
            <p class="card-subtitle">Detailed balance information by provider</p>
          </div>
          <Button
              icon="pi pi-sync"
              class="p-button-text refresh-button"
              @click="refreshBalances"
              :loading="isRefreshing"
              tooltip="Refresh Balance Data"
          />
        </div>
        <div class="card-content">
          <div class="balance-list">
            <TransitionGroup name="balance-item">
              <div
                  v-for="balance in balances"
                  :key="balance.collabId"
                  class="balance-item"
              >
                <div class="balance-header">
                  <div class="provider-info">
                    <div class="provider-icon-wrapper">
                      <i :class="getProviderIcon(balance.providerPathSegment)"></i>
                    </div>
                    <div class="provider-details">
                      <h4>{{ getProviderName(balance.collabId) }}</h4>
                      <span class="provider-id">Collab ID: {{ balance.collabId }}</span>
                    </div>
                  </div>
                  <div :class="['balance-status', balance.isLive ? 'status-success' : 'status-pending']">
                    <i :class="balance.isLive ? 'pi pi-check-circle' : 'pi pi-clock'"></i>
                    <span>{{ balance.isLive ? 'Live' : 'Sandbox' }}</span>
                  </div>
                </div>

                <div class="balance-details">
                  <div class="balance-amount-wrapper">
                    <div class="balance-amount-label">Available Balance</div>
                    <div class="balance-amount" :class="{'negative': isNegativeBalance(balance)}">
                      {{ formatCurrency(balance.amount, balance.currency) }}
                    </div>
                  </div>

                  <div class="balance-data-grid">
                    <div class="balance-data-item">
                      <div class="data-label">Currency</div>
                      <div class="data-value">{{ balance.currency }}</div>
                    </div>
                    <div class="balance-data-item">
                      <div class="data-label">Updated</div>
                      <div class="data-value">{{ formatDate(balance.updatedAt) }}</div>
                    </div>
                    <div class="balance-data-item">
                      <div class="data-label">Account Type</div>
                      <div class="data-value">{{ balance.accountType || 'Standard' }}</div>
                    </div>
                  </div>

                  <!-- Raw Response Data -->
                  <JsonDisplay
                          :content="balance.rawData"
                          label="Provider Raw Response Data"
                          :is-dark-theme="isDarkTheme"
                          :initially-expanded="false"
                  />
                </div>
              </div>
            </TransitionGroup>
          </div>
        </div>
      </div>
    </div>
    <Toast position="bottom-right" />
  </div>
  <div v-if="!isAdminOrMerchant">
    You are not authorized to view this page.
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Toast from 'primevue/toast';
import Button from 'primevue/button';
import { useToast } from "primevue/usetoast";
import axios from 'axios';
import JsonDisplay from "@/components/transactions/JsonDisplayComponent.vue";

export default {
  components: {
    JsonDisplay,
    Toast,
    Button
  },
  setup() {
    const toast = useToast();
    return {toast};
  },
  name: 'BalancePage',
  data() {
    return {
      isLoading: true,
      isRefreshing: false,
      refreshingBalanceIds: [],
      balanceSupportedProviders: [
        { providerId: 3, providerPathSegment: 'fb-pay' },
        { providerId: 4, providerPathSegment: 'rolling-pay' },
        { providerId: 5, providerPathSegment: 'hum-pas' },
        { providerId: 19, providerPathSegment: 'qrph' },
      ],
      balances: [],
      expandedBalances: {}, // To track expanded/collapsed state of additional balances
      expandedResponses: {}, // To track expanded/collapsed state of raw responses
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('providers', ['providers']),
    ...mapState('collabs', ['collabs']),
    isDarkTheme() {
      return this.$store.state.isDarkTheme;
    },
    isAdmin() {
      return this.user && this.user.roles.includes('Admin');
    },
    isMerchant() {
      return this.user && this.user.roles.includes('Merchant Admin');
    },
    isAdminOrMerchant() {
      return this.isAdmin || this.isMerchant;
    }
  },
  // Change in the created() hook to fix both issues
  async created() {
    this.isLoading = true;
    try {
      if (this.isAdmin) {
        const providers = await this.fetchProviders();
        await this.fetchCollabsByProviderIds(providers.map(provider => provider.id));
      } else {
        await this.fetchCollabsByMerchantId(this.user.merchantId);
      }

      if (!this.collabs || this.collabs.length === 0) {
        return
      }

      const collabsWithBalanceInfo = this.collabs.filter(collab =>
          this.balanceSupportedProviders.some(provider => provider.providerId === collab.provider.id)
      ).map(collab => {
        const matchingProvider = this.balanceSupportedProviders.find(
            provider => provider.providerId === collab.provider.id
        );
        return {
          collabId: collab.id,
          providerPathSegment: matchingProvider.providerPathSegment
        };
      });

      const baseUrl = process.env.VUE_APP_GATEWAY_API_BASE_URL;
      this.balances = await Promise.all(collabsWithBalanceInfo.map(async collab => {
        try {
          const response = await axios.get(`${baseUrl}/api/v1/providers/${collab.providerPathSegment}/balance?collabId=${collab.collabId}`);
          const rawData = response.data;
          // Process the data through parseBalanceResponse to ensure consistency
          const parsedData = this.parseBalanceResponse(rawData);

          return {
            ...parsedData,
            collabId: collab.collabId,
            providerPathSegment: collab.providerPathSegment,
            rawData: rawData  // Store the raw response data
          };
        } catch (error) {
          console.error(`Failed to fetch balance for collab ${collab.collabId}:`, error);
          // Return a placeholder object if this particular balance fetch fails
          return {
            amount: null,
            currency: 'PHP',
            updatedAt: null,
            isLive: false,
            accountId: null,
            accountType: null,
            additionalBalances: [],
            status: 'error',
            error: error.message,
            collabId: collab.collabId,
            providerPathSegment: collab.providerPathSegment,
            rawData: { error: error.message }
          };
        }
      }));

    } catch (error) {
      this.toast.add({ severity: 'error', summary: 'Error',
        detail: 'Failed to load balances: ' + error.message, life: 6000 });
    } finally {
      this.isLoading = false;
    }
  },
  mounted() {
    document.title = "Balances";
  },
  methods: {
    ...mapActions('providers', ['fetchProviders']),
    ...mapActions('collabs', ['fetchCollabsByMerchantId', 'fetchCollabsByProviderIds']),

    getProviderName(collabId) {
      const collab = this.collabs.find(c => c.id === collabId);
      if (!collab) return 'Unknown';
      if (!collab.provider) return 'Unknown';
      if (!collab.merchant) return collab.provider.name;
      return `${collab.merchant.name} - ${collab.provider.name}`;
    },

    getProviderIcon(pathSegment) {
      const iconMap = {
        'fb-pay': 'pi pi-credit-card',
        'rolling-pay': 'pi pi-credit-card',
        'hum-pas': 'pi pi-credit-card',
        'qrph': 'pi pi-credit-card'
      };

      return iconMap[pathSegment] || 'pi pi-credit-card';
    },

    formatCurrency(amount, currency) {
      if (amount === null || amount === undefined) return '-';

      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency || 'PHP',
        minimumFractionDigits: 2
      }).format(amount);
    },

    formatDate(dateString) {
      /*if (!dateString)*/ dateString = new Date().toISOString();

      const date = new Date(dateString);
      return new Intl.DateTimeFormat('en-US', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      }).format(date);
    },

    formatTotalAmount() {
      if (this.balances.length === 0) return '$0.00';

      // Group balances by currency
      const currencyGroups = {};
      this.balances.forEach(balance => {
        if (!balance.amount) return;

        const currency = balance.currency || 'PHP';
        if (!currencyGroups[currency]) {
          currencyGroups[currency] = 0;
        }
        currencyGroups[currency] += parseFloat(balance.amount);
      });

      // Format each currency group
      return Object.entries(currencyGroups)
          .map(([currency, amount]) => this.formatCurrency(amount, currency))
          .join(' / ');
    },

    formatLastUpdated() {

      const mostRecent = new Date()
      return new Intl.DateTimeFormat('en-US', {
        day: 'numeric',
        month: 'short',
        hour: '2-digit',
        minute: '2-digit'
      }).format(mostRecent);
    },

    isNegativeBalance(balance) {
      return balance.amount !== null && parseFloat(balance.amount) <= 0;
    },

    hasAdditionalBalances(balance) {
      return balance.additionalBalances && balance.additionalBalances.length > 0;
    },

    toggleBalanceDetails(collabId) {
      this.$set(this.expandedBalances, collabId, !this.expandedBalances[collabId]);
    },

    toggleResponseDetails(collabId) {
      if (!this.expandedResponses[collabId]) {
        return
      }
      this.$set(this.expandedResponses, collabId, !this.expandedResponses[collabId]);
    },

    formatBalanceLabel(key) {
      // Convert camelCase or snake_case keys to readable labels
      return key
          .replace(/([A-Z])/g, ' $1') // Insert space before capital letters
          .replace(/_/g, ' ') // Replace underscores with spaces
          .replace(/\./g, ' → ') // Replace dots with arrows for nested properties
          .replace(/^./, str => str.toUpperCase()) // Capitalize the first letter
          .replace(/balance/i, 'Balance') // Ensure 'balance' is capitalized
          .replace(/amount/i, 'Amount'); // Ensure 'amount' is capitalized
    },

    parseBalanceResponse(responseData) {
      // Try to extract balance amount
      let amount = null;
      let currency = 'PHP'; // Default currency
      let updatedAt = null;
      let isLive = true;  // Default to true
      let accountId = null;
      let accountType = null;
      let additionalBalances = [];

      // Define search patterns for different response formats
      const balancePatterns = [
        'balance',
        'amount',
        'balanceAmount',
        'availableBalance'
      ];

      const datePatterns = [
        'datetime',
        'date',
        'updatedAt',
        'created_at',
        'timestamp'
      ];

      const statusPatterns = {
        success: [
          { field: 'code', value: 0 },
          { field: 'text', value: 'success' },
          { field: 'success', value: true },
          { field: 'request_success', value: true },
          { field: 'system_msg', value: 'SUCCESS' },
          { field: 'message', value: 'Success' }
        ]
      };

      // Function to recursively search for keys in an object
      const findValueByPatterns = (obj, patterns, depth = 0, maxDepth = 3) => {
        if (!obj || typeof obj !== 'object' || depth > maxDepth) return null;

        // Check if any pattern directly matches a key
        for (const pattern of patterns) {
          if (obj[pattern] !== undefined) return obj[pattern];
        }

        // Search in nested objects and arrays
        for (const key in obj) {
          if (Array.isArray(obj[key])) {
            for (const item of obj[key]) {
              const found = findValueByPatterns(item, patterns, depth + 1, maxDepth);
              if (found !== null) return found;
            }
          } else if (typeof obj[key] === 'object' && obj[key] !== null) {
            const found = findValueByPatterns(obj[key], patterns, depth + 1, maxDepth);
            if (found !== null) return found;
          }
        }

        return null;
      };

      // Collect all numeric values that could be balance amounts
      const collectAllBalances = (obj, prefix = '', result = []) => {
        if (!obj || typeof obj !== 'object') return result;

        for (const key in obj) {
          const value = obj[key];
          const fullKey = prefix ? `${prefix}.${key}` : key;

          if (typeof value === 'number' &&
              /balance|amount/i.test(key) &&
              !key.includes('guarantee') &&
              !key.includes('freeze')) {
            result.push({ key: fullKey, value });
          } else if (typeof value === 'object') {
            collectAllBalances(value, fullKey, result);
          }
        }

        return result;
      };

      // Extract main balance
      amount = findValueByPatterns(responseData, balancePatterns);

      // Extract date
      updatedAt = findValueByPatterns(responseData, datePatterns);

      // Check status
      let validStatus = false;
      for (const check of statusPatterns.success) {
        if (responseData[check.field] === check.value) {
          validStatus = true;
          break;
        }
      }

      // Extract all possible balance fields
      additionalBalances = collectAllBalances(responseData);

      // Remove the main balance from additional balances to avoid duplication
      if (amount !== null) {
        additionalBalances = additionalBalances.filter(b => b.value !== amount);
      }

      // Try to determine if this is a live account or sandbox
      if (responseData.isLive !== undefined) {
        isLive = !!responseData.isLive;
      } else if (responseData.mode !== undefined) {
        isLive = responseData.mode === 'live' || responseData.mode === 'production';
      } else if (responseData.environment !== undefined) {
        isLive = responseData.environment === 'live' || responseData.environment === 'production';
      }

      // Try to extract account ID
      if (responseData.accountId !== undefined) {
        accountId = responseData.accountId;
      } else if (responseData.account_id !== undefined) {
        accountId = responseData.account_id;
      } else if (responseData.id !== undefined) {
        accountId = responseData.id;
      } else if (responseData.intAccountSN !== undefined) {
        accountId = responseData.intAccountSN;
      }

      // Try to extract account type
      if (responseData.accountType !== undefined) {
        accountType = responseData.accountType;
      } else if (responseData.account_type !== undefined) {
        accountType = responseData.account_type;
      } else if (responseData.type !== undefined) {
        accountType = responseData.type;
      }

      // Extract currency
      if (responseData.currency !== undefined) {
        currency = responseData.currency;
      } else if (responseData.currencyCode !== undefined) {
        currency = responseData.currencyCode;
      } else if (responseData.currency_code !== undefined) {
        currency = responseData.currency_code;
      }

      return {
        amount,
        currency,
        updatedAt,
        isLive,
        accountId,
        accountType,
        additionalBalances,
        status: validStatus ? 'success' : 'unknown'
      };
    },

    async refreshBalances() {
      this.isRefreshing = true;
      try {
        const baseUrl = process.env.VUE_APP_GATEWAY_API_BASE_URL;
        this.balances = await Promise.all(this.balances.map(async balance => {
          const response = await axios.get(
              `${baseUrl}/api/v1/providers/${balance.providerPathSegment}/balance?collabId=${balance.collabId}`
          );
          const rawData = response.data;
          const parsedData = this.parseBalanceResponse(rawData);

          return {
            ...parsedData,
            collabId: balance.collabId,
            providerPathSegment: balance.providerPathSegment,
            rawData: rawData
          };
        }));

        this.toast.add({
          severity: 'success',
          summary: 'Balances Updated',
          detail: 'All balance information has been refreshed',
          life: 3000
        });
      } catch (error) {
        this.toast.add({
          severity: 'error',
          summary: 'Refresh Failed',
          detail: `Failed to refresh balances: ${error.message}`,
          life: 5000
        });
      } finally {
        this.isRefreshing = false;
      }
    },

    async refreshSingleBalance(balance) {
      this.refreshingBalanceIds.push(balance.collabId);
      try {
        const baseUrl = process.env.VUE_APP_GATEWAY_API_BASE_URL;
        const response = await axios.get(
            `${baseUrl}/api/v1/providers/${balance.providerPathSegment}/balance?collabId=${balance.collabId}`
        );
        const rawData = response.data;
        const parsedData = this.parseBalanceResponse(rawData);

        // Update the specific balance in the array
        const index = this.balances.findIndex(b => b.collabId === balance.collabId);
        if (index !== -1) {
          this.balances[index] = {
            ...parsedData,
            collabId: balance.collabId,
            providerPathSegment: balance.providerPathSegment,
            rawData: rawData
          };
        }

        this.toast.add({
          severity: 'success',
          summary: 'Balance Updated',
          detail: `${this.getProviderName(balance.collabId)} balance has been refreshed`,
          life: 3000
        });
      } catch (error) {
        this.toast.add({
          severity: 'error',
          summary: 'Refresh Failed',
          detail: `Failed to refresh balance: ${error.message}`,
          life: 5000
        });
      } finally {
        this.refreshingBalanceIds = this.refreshingBalanceIds.filter(id => id !== balance.collabId);
      }
    },
  }
}
</script>

<style scoped>
/* Base Layout */
.balance-page {
  padding: 2rem;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  background-color: var(--surface-ground);
}

.balance-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

/* Loading States */
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  gap: 1.5rem;
}

.loading-spinner {
  font-size: 3rem;
  color: var(--primary-color);
}

.loading-text {
  font-size: 1.25rem;
  color: var(--text-color-secondary);
  font-weight: 500;
}

/* Empty State */
.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  text-align: center;
  padding: 2rem;
}

.empty-icon {
  font-size: 4rem;
  color: var(--text-color-secondary);
  opacity: 0.6;
  margin-bottom: 1.5rem;
}

.empty-state h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: var(--text-color);
}

.empty-state p {
  font-size: 1.1rem;
  color: var(--text-color-secondary);
  max-width: 500px;
}

/* Card Styles */
.info-card {
  background-color: var(--surface-card);
  border-radius: 1rem;
  padding: 2rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.06);
  transition: all 0.2s ease-in-out;
}

.info-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.card-header {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid var(--surface-border);
  gap: 1rem;
}

.header-icon-container {
  background: var(--primary-color);
  border-radius: 50%;
  padding: 1rem;
  display: flex;
}

.header-icon {
  font-size: 1.75rem;
  color: white;
}

.card-header h3 {
  margin: 0;
  font-size: 1.3rem;
  color: var(--text-color);
  font-weight: 600;
}

.card-subtitle {
  color: var(--text-color-secondary);
  font-size: 0.9rem;
  margin-top: 0.25rem;
}

.refresh-button {
  margin-left: auto;
  width: 2.5rem;
  height: 2.5rem;
  flex-shrink: 0;
}

/* Balance Summary Styles */
.balance-summary-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.summary-item {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.summary-icon-wrapper {
  background-color: var(--primary-color);
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.summary-icon-wrapper i {
  font-size: 1.75rem;
  color: white;
}

.summary-data h4 {
  margin: 0 0 0.5rem 0;
  font-size: 1rem;
  color: var(--text-color-secondary);
  font-weight: 500;
}

.summary-amount {
  font-size: 1.75rem;
  font-weight: 700;
  color: var(--text-color);
}

.summary-date {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--text-color);
}

/* Balance Item Styles */
.balance-list {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.balance-item {
  background-color: var(--surface-section);
  border-radius: 1rem;
  padding: 1.5rem;
  transition: all 0.2s ease;
  border: 1px solid var(--surface-border);
}

.balance-item:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border-color: var(--primary-color);
}

.balance-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.provider-info {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.provider-icon-wrapper {
  background-color: var(--primary-color);
  width: 3rem;
  height: 3rem;
  border-radius: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.provider-icon-wrapper i {
  font-size: 1.5rem;
  color: white;
}

.provider-details h4 {
  margin: 0;
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--text-color);
}

.provider-id {
  font-size: 0.875rem;
  color: var(--text-color-secondary);
}

.balance-status {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  font-size: 0.875rem;
  font-weight: 600;
}

.status-success {
  background-color: var(--green-50);
  color: var(--green-800);
}

.status-pending {
  background-color: var(--yellow-50);
  color: var(--yellow-800);
}

.status-error {
  background-color: var(--red-50);
  color: var(--red-800);
}

/* Balance Details */
.balance-details {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  /*margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid var(--surface-border);*/
}

/* Additional Balances */
.additional-balances {
  background-color: var(--surface-ground);
  border-radius: 0.75rem;
  padding: 1rem;
  border: 1px solid var(--surface-border);
}

.additional-balances-header,
.response-data-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.additional-balances-content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
}

.additional-balance-item {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  padding: 0.75rem;
  background-color: var(--surface-card);
  border-radius: 0.5rem;
  transition: all 0.2s ease;
}

.additional-balance-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

.additional-balance-label {
  font-size: 0.75rem;
  color: var(--text-color-secondary);
  font-weight: 500;
}

.additional-balance-value {
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--text-color);
}

.additional-balance-value.negative {
  color: var(--red-600);
}

/* Raw Response Data */
.response-data {
  background-color: var(--surface-ground);
  border-radius: 0.75rem;
  padding: 1rem;
  border: 1px solid var(--surface-border);
}

.response-data-content {
  margin-top: 1rem;
  max-height: 300px;
  overflow-y: auto;
}

.json-response {
  font-family: 'Fira Code', monospace;
  font-size: 0.85rem;
  background-color: var(--surface-card);
  padding: 1rem;
  border-radius: 0.5rem;
  overflow-x: auto;
  white-space: pre-wrap;
}

.toggle-button {
  width: 2.5rem;
  height: 2.5rem;
}

.balance-amount-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.balance-amount-label {
  font-size: 0.875rem;
  color: var(--text-color-secondary);
}

.balance-amount {
  font-size: 2rem;
  font-weight: 700;
  color: var(--green-600);
}

.balance-amount.negative {
  color: var(--red-600);
}

.balance-data-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1.5rem;
}

.balance-data-item {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.data-label {
  font-size: 0.75rem;
  color: var(--text-color-secondary);
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.data-value {
  font-size: 1rem;
  font-weight: 600;
  color: var(--text-color);
}

.account-id {
  font-family: monospace;
  font-size: 0.9rem;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

/* Balance Actions */
.balance-actions {
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
}

.history-button {
  font-weight: 600;
}

/* Transitions */
.validation-item-enter-active,
.validation-item-leave-active,
.balance-item-enter-active,
.balance-item-leave-active {
  transition: all 0.3s ease;
}

.validation-item-enter-from,
.validation-item-leave-to,
.balance-item-enter-from,
.balance-item-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

/* Dark Theme Adjustments */
.dark-theme {
  .info-card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .balance-item {
    background-color: var(--surface-card);
    border-color: var(--surface-border);
  }

  .balance-item:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  .status-success {
    background-color: var(--green-900);
    color: var(--green-100);
  }

  .status-pending {
    background-color: var(--yellow-900);
    color: var(--yellow-100);
  }

  .status-error {
    background-color: var(--red-900);
    color: var(--red-100);
  }

  .provider-icon-wrapper {
    background-color: var(--primary-700);
  }

  .summary-icon-wrapper {
    background-color: var(--primary-700);
  }

  .balance-amount {
    color: var(--green-400);
  }

  .balance-amount.negative {
    color: var(--red-400);
  }

  .additional-balance-value.negative {
    color: var(--red-400);
  }

  .json-response {
    background-color: var(--surface-700);
    color: var(--text-color);
  }
}

/* Responsive Design */
@media (max-width: 1024px) {
  .balance-page {
    padding: 1.5rem;
  }

  .info-card {
    padding: 1.5rem;
  }

  .balance-summary-grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
}

@media (max-width: 768px) {
  .balance-page {
    padding: 1rem;
  }

  .info-card {
    padding: 1.25rem;
  }

  .card-header {
    margin-bottom: 1.5rem;
  }

  .balance-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .balance-status {
    align-self: flex-start;
  }

  .balance-data-grid {
    grid-template-columns: 1fr 1fr;
  }

  .balance-actions {
    flex-direction: column;
  }

  .history-button {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .balance-data-grid {
    grid-template-columns: 1fr;
  }
}
</style>
