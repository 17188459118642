<template>
  <div class="dashboard" :class="{ 'dark-mode': isDarkTheme }">
    <Toast />
    <ProgressSpinner v-if="loading" class="global-loader" strokeWidth="4" fill="var(--surface-ground)" animationDuration=".5s" />
    <div class="dashboard-header">
      <h1>Financial Dashboard</h1>
      <div class="dashboard-controls">
        <Dropdown v-model="selectedDateRange" :options="dateRangeOptions" optionLabel="label"
                  optionValue="value" placeholder="Select Time Range" class="p-inputtext-sm" @change="fetchData" />
        <Button icon="pi pi-refresh" class="p-button-rounded p-button-text" @click="fetchData" />
      </div>
    </div>

    <div class="dashboard-content">
      <!-- Summary Cards -->
      <div class="summary-cards">
        <div class="summary-card-wrapper" v-for="card in summaryCards" :key="card.title">
          <div class="summary-card">
            <div class="summary-icon" :style="{ backgroundColor: card.color }">
              <i :class="card.icon"></i>
            </div>
            <div class="summary-details">
              <h3>{{ card.title }}</h3>
              <h2>{{ card.value }}</h2>
            </div>
          </div>
        </div>
      </div>

      <!-- Transaction Status and Cash Flow Charts -->
      <div class="charts-container">
        <div class="chart-wrapper">
          <div class="chart-card">
            <h3>Transaction Status</h3>
            <div class="transaction-status-container">
              <Chart type="doughnut" :data="transactionStatusData" :options="doughnutChartOptions" class="chart" />
              <div class="transaction-status-details">
                <div v-for="(status) in transactionStatusDetails" :key="status.label" class="status-item">
                  <div class="status-color" :style="{ backgroundColor: status.color }"></div>
                  <div class="status-info">
                    <span class="status-label">{{ status.label }} ({{ status.value }})</span>
                    <span class="status-percentage">{{ status.percentage }}%</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="chart-wrapper">
          <div class="chart-card">
            <h3>Cash Flow Overview</h3>
            <Chart type="bar" :data="cashFlowData" :options="barChartOptions" class="chart" />
          </div>
        </div>
      </div>

      <!-- Transactions by Provider -->
      <div class="summary-cards">
      <div class="table-section">
        <h3>Transactions by Provider</h3>
        <DataTable :value="transactionsByProvider" responsiveLayout="stack" breakpoint="960px" :sortField="'count'" :sortOrder="-1" class="p-datatable-sm">
          <Column field="provider" header="Provider" sortable></Column>
          <Column field="count" header="Count" sortable></Column>
          <Column field="percentage" header="Percentage" sortable>
            <template #body="slotProps">
              <div class="flex align-items-center">
                <div class="w-8rem mr-2">
                  <ProgressBar :value="slotProps.data.percentage" :showValue="false" />
                </div>
                <span>{{ slotProps.data.percentage.toFixed(2) }}%</span>
              </div>
            </template>
          </Column>
        </DataTable>
      </div>

      <!-- Cash In/Out by Merchant -->
        <div class="custom-tabs" v-if="isAdmin">
          <div class="tab-header">
            <button
                v-for="tab in ['Cash In', 'Cash Out']"
                :key="tab"
                @click="activeTab = tab.toLowerCase().replace(' ', '')"
                :class="{ active: activeTab === tab.toLowerCase().replace(' ', '') }"
                class="tab-button"
            >
              {{ tab }}
            </button>
          </div>
          <div class="tab-content">
            <div v-if="activeTab === 'cashin'">
              <DataTable :value="cashInByMerchant" responsiveLayout="stack" breakpoint="960px" class="p-datatable-sm">
                <Column field="merchant" header="Merchant"></Column>
                <Column field="amount" header="Amount">
                  <template #body="slotProps">
                    {{ formatCurrency(slotProps.data.amount) }}
                  </template>
                </Column>
              </DataTable>
            </div>
            <div v-if="activeTab === 'cashout'">
              <DataTable :value="cashOutByMerchant" responsiveLayout="stack" breakpoint="960px" class="p-datatable-sm">
                <Column field="merchant" header="Merchant"></Column>
                <Column field="amount" header="Amount">
                  <template #body="slotProps">
                    {{ formatCurrency(slotProps.data.amount) }}
                  </template>
                </Column>
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted, watch } from 'vue';
import {mapState, useStore} from 'vuex';
import { useToast } from 'primevue/usetoast';
import Chart from 'primevue/chart';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dropdown from 'primevue/dropdown';
import Button from 'primevue/button';
import ProgressBar from 'primevue/progressbar';
import ProgressSpinner from 'primevue/progressspinner';
import Toast from 'primevue/toast';

export default defineComponent({
  name: 'DashboardPage',
  components: {
    Chart,
    DataTable,
    Column,
    Dropdown,
    Button,
    ProgressBar,
    ProgressSpinner,
    Toast,
  },
  setup() {
    const store = useStore();
    const toast = useToast();

    const loading = ref(false);
    const selectedDateRange = ref('1');
    const activeTab = ref('cashin');
    const dateRangeOptions = [
      { label: 'Last Day', value: '1' },
      { label: 'Last Week', value: '7' },
      { label: 'Last 14 Days', value: '14' },
      { label: 'Last 30 Days', value: '30' },
      { label: 'Last 90 Days', value: '90' },
      { label: 'Last 180 Days', value: '180' },
      { label: 'Last Year', value: '365' },
    ];

    const transactionData = ref({
      total: 0,
      successful: 0,
      failed: 0,
      pending: 0,
      refunded: 0,
      byProvider: {},
      byMerchant: {},
    });

    const cashInData = ref({});
    const cashOutData = ref({});
    const cashInDataByMerchant = ref({});
    const cashOutDataByMerchant = ref({});

    const summaryCards = computed(() => [
      { title: 'Total Transactions', value: transactionData.value.total, icon: 'pi pi-shopping-cart', color: 'var(--primary-color)' },
      { title: 'Success Rate', value: `${((transactionData.value.successful / transactionData.value.total) * 100).toFixed(2)}%`, icon: 'pi pi-check-circle', color: 'var(--green-500)' },
      { title: 'Total Volume', value: formatCurrency(calculateTotalVolume()), icon: 'pi pi-dollar', color: 'var(--orange-500)' },
      { title: 'Failed Transactions', value: transactionData.value.failed, icon: 'pi pi-times-circle', color: 'var(--pink-500)' },
    ]);

    const transactionsByProvider = computed(() =>
        Object.entries(transactionData.value.byProvider).map(([provider, count]) => ({
          provider,
          count,
          amount: 0,
          percentage: (count / transactionData.value.total * 100)
        }))
    );

    const cashInByMerchant = computed(() =>
        Object.entries(cashInDataByMerchant.value).map(([merchant, amount]) => ({
          merchant,
          amount
        }))
    );

    const cashOutByMerchant = computed(() =>
        Object.entries(cashOutDataByMerchant.value).map(([merchant, amount]) => ({
          merchant,
          amount
        }))
    );

    const isDarkTheme = computed(() => store.state.isDarkTheme);

    const chartHeight = '400px';

    const barChartOptions = computed(() => ({
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          backgroundColor: isDarkTheme.value ? 'rgba(0,0,0,0.8)' : 'rgba(255,255,255,0.8)',
          titleColor: isDarkTheme.value ? '#ffffff' : '#000000',
          bodyColor: isDarkTheme.value ? '#ffffff' : '#000000',
          borderColor: isDarkTheme.value ? '#ffffff' : '#000000',
          borderWidth: 1,
          cornerRadius: 8,
          displayColors: false,
          padding: 12,
          titleFont: { size: 16, weight: 'bold' },
          bodyFont: { size: 14 }
        }
      },
      scales: {
        x: {
          grid: {
            display: false,
            color: isDarkTheme.value ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)'
          },
          ticks: {
            color: isDarkTheme.value ? '#ffffff' : '#495057',
            font: {
              size: 14,
              weight: 'bold'
            }
          }
        },
        y: {
          beginAtZero: true,
          grid: {
            color: isDarkTheme.value ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)'
          },
          ticks: {
            color: isDarkTheme.value ? '#ffffff' : '#495057',
            font: {
              size: 14,
              weight: 'bold'
            },
            callback: (value) => `$${value}`
          }
        }
      },
      animation: {
        duration: 2000,
        easing: 'easeOutQuart'
      }
    }));

    const transactionStatusData = computed(() => ({
      labels: ['Successful', 'Failed', 'Pending'],
      datasets: [{
        data: [
          transactionData.value.successful,
          transactionData.value.failed,
          transactionData.value.pending,
        ],
        backgroundColor: transactionStatusColors,
        borderColor: isDarkTheme.value ? '#1e1e1e' : '#ffffff',
        borderWidth: 2,
        hoverOffset: 4
      }]
    }));

    const transactionStatusColors = [
      'rgba(75, 192, 192, 1)',  // Successful
      'rgba(255, 99, 132, 1)',  // Failed
      'rgba(255, 205, 86, 1)',  // Pending
    ];

    const transactionStatusDetails = computed(() => {
      const total = transactionData.value.total;
      return [
        { label: 'Successful', value: transactionData.value.successful, color: transactionStatusColors[0], percentage: ((transactionData.value.successful / total) * 100).toFixed(1) },
        { label: 'Failed', value: transactionData.value.failed, color: transactionStatusColors[1], percentage: ((transactionData.value.failed / total) * 100).toFixed(1) },
        { label: 'Pending', value: transactionData.value.pending, color: transactionStatusColors[2], percentage: ((transactionData.value.pending / total) * 100).toFixed(1) },
      ];
    });

    const doughnutChartOptions = computed(() => ({
      responsive: true,
      maintainAspectRatio: false,
      cutout: '75%',
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          backgroundColor: isDarkTheme.value ? 'rgba(0,0,0,0.8)' : 'rgba(255,255,255,0.8)',
          titleColor: isDarkTheme.value ? '#ffffff' : '#000000',
          bodyColor: isDarkTheme.value ? '#ffffff' : '#000000',
          borderColor: isDarkTheme.value ? '#ffffff' : '#000000',
          borderWidth: 1,
          cornerRadius: 8,
          displayColors: true,
          padding: 12,
          titleFont: { size: 16, weight: 'bold' },
          bodyFont: { size: 14 },
          callbacks: {
            label: function(context) {
              const label = context.label || '';
              const value = context.parsed || 0;
              const total = context.dataset.data.reduce((a, b) => a + b, 0);
              const percentage = ((value / total) * 100).toFixed(1);
              return `${label}: ${value} (${percentage}%)`;
            }
          }
        }
      },
      animation: {
        animateScale: true,
        animateRotate: true
      }
    }));

    const cashFlowData = computed(() => ({
      labels: ['Cash In', 'Cash Out'],
      datasets: [{
        label: 'Amount',
        data: [
          Object.values(cashInData.value).reduce((sum, amount) => sum + amount, 0),
          Object.values(cashOutData.value).reduce((sum, amount) => sum + amount, 0)
        ],
        backgroundColor: [
          'rgba(75, 192, 192, 0.8)',
          'rgba(255, 99, 132, 0.8)'
        ],
        borderColor: [
          'rgb(75, 192, 192)',
          'rgb(255, 99, 132)'
        ],
        borderWidth: 1,
        borderRadius: 10,
        hoverBackgroundColor: [
          'rgba(75, 192, 192, 1)',
          'rgba(255, 99, 132, 1)'
        ],
        hoverBorderColor: isDarkTheme.value ? '#ffffff' : '#000000',
        hoverBorderWidth: 2
      }]
    }));

    const fetchData = async () => {
      loading.value = true;
      try {
        const { currency, from, to } = getDateRange();
        const statistics = await store.dispatch('statistics/fetchStatistics', { currency, from, to });

        transactionData.value = {
          total: statistics.total,
          successful: statistics.successful,
          failed: statistics.failed,
          pending: statistics.pending,
          refunded: statistics.refunded,
          byProvider: statistics.byProvider,
          byMerchant: statistics.byMerchant,
        };
        cashInData.value = statistics.cashInData;
        cashOutData.value = statistics.cashOutData;
        cashInDataByMerchant.value = statistics.cashInDataByMerchant;
        cashOutDataByMerchant.value = statistics.cashOutDataByMerchant;

        toast.add({ severity: 'success', summary: 'Data Updated', detail: 'Dashboard data has been refreshed', life: 3000 });
      } catch (error) {
        console.error('Error fetching statistics:', error);
        toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to fetch dashboard data', life: 3000 });
      } finally {
        loading.value = false;
      }
    };

    const getDateRange = () => {
      const today = new Date();
      const from = new Date(today);
      from.setDate(from.getDate() - parseInt(selectedDateRange.value));
      return {
        currency: 'USD',
        from: from.toISOString(),
        to: today.toISOString(),
      };
    };

    const formatCurrency = (value) => {
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
    };

    const calculateTotalVolume = () => {
      return Object.values(cashInData.value).reduce((sum, amount) => sum + amount, 0);
    };

    onMounted(() => {
      fetchData();
    });

    watch(selectedDateRange, () => {
      fetchData();
    });

    return {
      loading,
      selectedDateRange,
      dateRangeOptions,
      activeTab,
      summaryCards,
      transactionsByProvider,
      cashInByMerchant,
      cashOutByMerchant,
      cashFlowData,
      barChartOptions,
      isDarkTheme,
      fetchData,
      formatCurrency,
      doughnutChartOptions,
      chartHeight,
      transactionStatusData,
      transactionStatusDetails
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    isAdmin() {
      return this.user && this.user.roles.includes('Admin');
    },
  },
  mounted() {
    document.title = "Dashboard";
  },
});
</script>

<style scoped>
.dashboard {
  background-color: var(--surface-ground);
  min-height: 100vh;
  padding: 100px 10rem 5rem;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.dashboard-header h1 {
  font-size: 2rem;
  color: var(--text-color);
  margin: 0;
}

.dashboard-controls {
  display: flex;
  gap: 1rem;
}

.dashboard-content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

/* Modern and stylish summary cards */
.summary-cards {
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
}

.summary-card {
  background: var(--surface-card);
  border-radius: 1rem;
  padding: 1.5rem;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.summary-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.summary-icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
}

.summary-icon i {
  font-size: 1.5rem;
  color: white;
}

.summary-details h3 {
  font-size: 0.9rem;
  color: var(--text-color-secondary);
  margin: 0 0 0.5rem 0;
}

.summary-details h2 {
  font-size: 1.5rem;
  color: var(--text-color);
  margin: 0;
}

.charts-container {
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.chart-card {
  background: var(--surface-card);
  border-radius: 1rem;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  height: 100%;
}

.chart-card h3 {
  font-size: 1.2rem;
  color: var(--text-color);
  margin: 0 0 1rem 0;
}

.custom-tabs {
  background: var(--surface-card);
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.tab-header {
  display: flex;
  background: var(--surface-ground);
}

.tab-button {
  flex: 1;
  padding: 1rem;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 1rem;
  color: var(--text-color-secondary);
  transition: all 0.3s ease;
}

.tab-button.active {
  background: var(--primary-color);
  color: var(--primary-color-text);
}

.tab-content {
  padding: 1.5rem;
}

.table-section {
  background: var(--surface-card);
  border-radius: 1rem;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.table-section h3 {
  font-size: 1.2rem;
  color: var(--text-color);
  margin: 0 0 1rem 0;
}

@media (max-width: 768px) {
  .dashboard-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .dashboard-controls {
    width: 100%;
  }
}

.transaction-status-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chart {
  flex: 1;
  max-width: 100%;
}

.transaction-status-details {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 2rem;
}

.status-item {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.status-color {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 0.75rem;
}

.status-info {
  display: flex;
  flex-direction: column;
}

.status-label {
  font-size: 0.9rem;
  color: var(--text-color-secondary);
}

.status-value {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--text-color);
}

.status-percentage {
  font-size: 0.9rem;
  color: var(--text-color-secondary);
}

@media (max-width: 768px) {
  .transaction-status-container {
    flex-direction: column;
  }

  .chart {
    max-width: 100%;
    margin-bottom: 1.5rem;
  }

  .transaction-status-details {
    padding-left: 0;
  }
}
</style>
