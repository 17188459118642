<template>
  <nav class="sidebar bg-dark text-white p-3" :class="{ 'dark-theme': currentTheme === 'dark', 'open': isOpen }">
    <div class="sidebar-header mt-5 p-1">
      <h4>Yapay Dashboard</h4>
      <small v-if="user">Logged in as <b>{{ user.username }}</b></small>
    </div>
    <ul class="nav flex-column mb-auto mt-5">
      <li class="nav-item" v-if="isAdmin || isMerchant">
        <router-link to="/" class="nav-link text-white" exact-active-class="active">
          <i class="fas fa-tachometer-alt mr-2"></i>
          <span>Dashboard</span>
        </router-link>
      </li>
      <li class="nav-item" v-if="isAdmin || isMerchant || isFinanceManager">
        <router-link to="/providers" class="nav-link text-white" active-class="active">
          <i class="fas fa-server mr-2"></i>
          <span>Providers</span>
        </router-link>
      </li>
      <li class="nav-item" v-if="isAdmin || isMerchant">
        <router-link to="/balances" class="nav-link text-white" active-class="active">
          <i class="fas fa-wallet mr-2"></i>
          <span>Balances</span>
        </router-link>
      </li>
      <li class="nav-item" v-if="isAdmin">
        <router-link to="/merchants" class="nav-link text-white" active-class="active">
          <i class="fas fa-store mr-2"></i>
          <span>Merchants</span>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link to="/transactions" class="nav-link text-white" active-class="active">
          <i class="fas fa-exchange-alt mr-2"></i>
          <span>Transactions</span>
        </router-link>
      </li>
      <li class="nav-item" v-if="!isAdmin && isMerchant">
        <router-link to="/callbacks" class="nav-link text-white" active-class="active">
          <i class="fas fa-reply mr-2"></i>
          <span>Callbacks</span>
        </router-link>
      </li>
      <li class="nav-item" v-if="isAdmin || isMerchant">
        <router-link to="/validation-endpoints" class="nav-link text-white" active-class="active">
          <i class="fas fa-clipboard-check mr-2"></i>
          <span>Validation Endpoints</span>
        </router-link>
      </li>
      <li class="nav-item" v-if="isAdmin && !isMerchant">
        <router-link to="/credentials" class="nav-link text-white" active-class="active">
          <i class="pi pi-key mr-2"></i>
          <span>Credentials</span>
        </router-link>
      </li>
      <li v-if="user.permissions.includes('Allow IP')" class="nav-item">
        <router-link to="/allowed-ips" class="nav-link text-white" active-class="active">
          <i class="fas fa-unlock mr-2"></i>
          <span>Allowed IPs</span>
        </router-link>
      </li>
      <li class="nav-item" v-if="isAdmin && !isMerchant">
        <router-link to="/simulate-payment" class="nav-link text-white" active-class="active">
          <i class="pi pi-wallet mr-2"></i>
          <span>Create Transaction</span>
        </router-link>
      </li>
    </ul>
    <ul class="nav flex-column mt-4">
      <li class="nav-item">
        <a v-if="isAdmin | isMerchant" href="https://documentation.yapay.io" target="_blank"
           class="nav-link text-white">
          <i class="fas fa-book mr-2"></i>
          <span>Documentation</span>
        </a>
        <a href="#" class="nav-link text-white" @click.prevent="toggleTheme">
          <i class="fas fa-adjust mr-2"></i>
          <span>{{ isDarkTheme ? 'Light' : 'Dark' }} Mode</span>
        </a>
      </li>
      <li class="nav-item">
        <a href="#" class="nav-link text-white" @click.prevent="logout">
          <i class="fas fa-sign-out-alt mr-2"></i>
          <span>Logout</span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'AppNavigation',
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(['isDarkTheme']),
    ...mapState('auth', ['user']),
    isAdmin() {
      return this.user && this.user.roles.includes('Admin');
    },
    isMerchant() {
      return this.user && this.user.roles.includes('Merchant Admin');
    },
    isFinanceManager() {
      return this.user && this.user.roles.includes('Merchant Finance Manager');
    },
    currentTheme() {
      return this.$store.state.isDarkTheme ? 'dark' : 'light';
    },
  },
  methods: {
    ...mapActions('auth', ['logout']),
    ...mapActions(['toggleTheme']),
  },
};
</script>

<style scoped>
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  min-height: 100vh;
  transition: background-color 0.3s, transform 0.3s;
  transform: translateX(-100%);
  padding-top: 60px;
}

.sidebar.open {
  transform: translateX(0);
}

.nav-link {
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
}

.nav-link:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.nav-link.active {
  background-color: #3b3b3b;
}

.nav-link i {
  margin-right: 10px;
}

.dark-theme .sidebar {
  background-color: #222 !important;
}
</style>
