export class LogParser {
    constructor(rawData) {
        this.rawData = rawData;
        this.stages = {
            INIT: 'Initialization',
            CREATION: 'Transaction Creation',
            REQUEST: 'API Request',
            RESPONSE: 'API Response',
            POLLING: 'Polling',
            CALLBACK: 'Callback Processing',
            STATUS: 'Status Change',
            SIGNALR: 'SignalR UI Update',
        };
    }

    parse() {
        try {
            const parsedData = typeof this.rawData === 'string' ? JSON.parse(this.rawData) : this.rawData;
            const streams = parsedData?.data?.result || [];

            const result = {
                transaction: this.extractTransactionInfo(streams),
                timeline: this.buildTimeline(streams),
                errors: this.extractErrors(streams),
                summary: this.buildProcessingSummary(streams),
                stats: parsedData?.stats || {},
            };

            // post-process timeline to enhance events
            result.timeline = this.enhanceTimelineWithStages(result.timeline);
            result.timeline = result.timeline.map(event => this.enhanceTimelineEvent(event));

            return result;
        } catch (error) {
            console.error('Error parsing logs:', error);
            throw new Error('Failed to parse logs: ' + error.message);
        }
    }

    determineStage(event) {
        const msg = event.message?.toLowerCase() || '';
        const application = event.application?.toLowerCase() || '';
        const regex = /sending transaction \d+ update:/;

        if (regex.test(msg)) {
            return this.stages.SIGNALR;
        }
        if (msg.includes('initializ') || msg.includes('created by user')) {
            return this.stages.INIT;
        }
        if (msg.includes('status changed') || msg.includes('status updated')) {
            return this.stages.STATUS;
        }
        if (msg.includes('polling') || application.includes('polling')) {
            return this.stages.POLLING;
        }
        if (msg.includes('http request')) {
            return this.stages.REQUEST;
        }
        if (msg.includes('http response')) {
            return this.stages.RESPONSE;
        }
        if (msg.includes('transaction message') || msg.includes('transaction created')) {
            return this.stages.CREATION;
        }

        if (application.includes('callback')) {
            return this.stages.CALLBACK;
        }

        return this.stages.INIT;
    }

    enhanceTimelineWithStages(timeline) {
        let currentStage = this.stages.INIT;
        const transactionStages = new Map();

        return timeline.map(event => {
            const determinedStage = this.determineStage(event);

            // track stages per transaction ID
            const transactionId = this.extractTransactionId(event);
            if (transactionId) {
                if (!transactionStages.has(transactionId)) {
                    transactionStages.set(transactionId, determinedStage);
                }
                currentStage = transactionStages.get(transactionId);

                // update stage if it's changed
                if (determinedStage !== this.stages.INIT) {
                    currentStage = determinedStage;
                    transactionStages.set(transactionId, currentStage);
                }
            }

            return {
                ...event,
                stage: determinedStage || currentStage
            };
        });
    }

    extractTransactionId(event) {
        const msg = event.message;
        const idMatch = msg?.match(/transaction (\d+)/i) ||
            msg?.match(/TransactionId["|:](\d+)/i);
        return idMatch ? idMatch[1] : null;
    }

    enhanceTimelineEvent(event) {
        const enhanced = { ...event };

        if (event.message?.includes('HTTP')) {
            enhanced.httpDetails = this.extractHttpDetails(event.message);
        }

        return enhanced;
    }

    extractHttpDetails(message) {
        const details = {};

        const methodMatch = message.match(/HTTP (?:Request|Response).*?(GET|POST|PUT|DELETE|PATCH) ([^\s"]+)/i);
        if (methodMatch) {
            details.method = methodMatch[1];
            details.url = methodMatch[2].replace(/\\"/g, '"');
        }

        const statusMatch = message.match(/client: (\w+)/) ||
            message.match(/StatusCode["|:]\s*"?(\w+)"?/i);
        if (statusMatch) {
            details.status = statusMatch[1];
        }

        const timeMatch = message.match(/Elapsed time: (\d+) ms/) ||
            message.match(/ElapsedMilliseconds["|:]\s*"?(\d+)"?/i);
        if (timeMatch) {
            details.elapsedTime = parseInt(timeMatch[1]);
        }

        return Object.keys(details).length > 0 ? details : null;
    }

    buildProcessingSummary(streams) {
        const summary = {
            totalDuration: 0,
            stages: {},
            statusChanges: [],
            pollingAttempts: 0,
            apiCalls: 0,
            errors: 0,
            finalStatus: null,
            providerType: null
        };

        let firstTimestamp = null;
        let lastTimestamp = null;

        streams.forEach(stream => {
            const streamData = stream.stream || {};

            // determine provider type
            if (streamData.ProviderName && !summary.providerType) {
                summary.providerType = streamData.ProviderName;
            }

            stream.values.forEach(([timestamp, messageJson]) => {
                try {
                    const ts = parseInt(timestamp) / 1000000;
                    if (!firstTimestamp || ts < firstTimestamp) firstTimestamp = ts;
                    if (!lastTimestamp || ts > lastTimestamp) lastTimestamp = ts;

                    const message = typeof messageJson === 'string'
                        ? JSON.parse(messageJson)
                        : messageJson;

                    if (message.Message?.toLowerCase().endsWith('polling retries.')) {
                        summary.pollingAttempts++;
                    }
                    if (message.Message?.includes('HTTP Request')) {
                        summary.apiCalls++;
                    }
                    if (streamData.level === 'error') {
                        summary.errors++;
                    }

                    // track status changes with more detail
                    if (message.Message?.includes('status changed')) {
                        const statusMatch = message.Message.match(/status changed from (\w+) to (\w+)/i);
                        if (statusMatch) {
                            summary.statusChanges.push({
                                from: statusMatch[1],
                                to: statusMatch[2],
                                timestamp: new Date(ts),
                                message: message.StatusMessage || message.Message
                            });
                            summary.finalStatus = statusMatch[2];
                        }
                    }

                    // track processing stages
                    const stage = this.determineStage({ message: message.Message });
                    summary.stages[stage] = (summary.stages[stage] || 0) + 1;

                } catch (error) {
                    console.warn('Error processing summary entry:', error);
                }
            });
        });

        if (firstTimestamp && lastTimestamp) {
            summary.totalDuration = Math.round(lastTimestamp - firstTimestamp);
        }

        summary.statusChanges.sort((a, b) => a.timestamp - b.timestamp);

        return summary;
    }

    extractTransactionInfo(streams) {
        const transactionInfo = {};
        let latestTimestamp = 0;

        streams.forEach(stream => {
            stream.values.forEach(([timestamp, messageJson]) => {
                try {
                    const ts = parseInt(timestamp) / 1000000;
                    const message = typeof messageJson === 'string'
                        ? JSON.parse(messageJson)
                        : messageJson;

                    // extract from Transaction Updates
                    if (message.TransactionUpdate) {
                        const update = typeof message.TransactionUpdate === 'string'
                            ? JSON.parse(message.TransactionUpdate)
                            : message.TransactionUpdate;

                        if (ts > latestTimestamp) {
                            Object.assign(transactionInfo, {
                                id: update.TransactionId,
                                provider: update.ProviderName,
                                amount: update.Amount,
                                status: update.Status,
                                statusCode: update.StatusCode,
                                merchant: update.AdditionalProperties?.Merchant,
                                clientId: update.AdditionalProperties?.['Client ID'],
                                created: update.AdditionalProperties?.Created,
                                updated: update.AdditionalProperties?.Updated,
                                callbackStatus: update.AdditionalProperties?.['Callback Delivery Status'],
                            });
                            latestTimestamp = ts;
                        }
                    }

                    // extract from other message types
                    if (ts > latestTimestamp) {
                        if (message.TransactionStatus) {
                            transactionInfo.latestStatus = message.TransactionStatus;
                        }
                        if (message.ProviderTransactionId) {
                            transactionInfo.providerTransactionId = message.ProviderTransactionId;
                        }
                        if (message.TransactionMessageId) {
                            transactionInfo.messageId = message.TransactionMessageId;
                        }
                    }
                } catch (error) {
                    console.warn('Error parsing transaction info:', error);
                }
            });
        });

        return transactionInfo;
    }

    extractErrors(streams) {
        const errors = [];

        streams.forEach(stream => {
            if (stream.stream?.level === 'error') {
                stream.values.forEach(([timestamp, messageJson]) => {
                    try {
                        const message = typeof messageJson === 'string'
                            ? JSON.parse(messageJson)
                            : messageJson;
                        const timeObj = new Date(parseInt(timestamp) / 1000000);

                        if (!isNaN(timeObj.getTime())) {
                            errors.push({
                                timestamp: timeObj,
                                message: message.Message,
                                source: message.SourceContext,
                                application: stream.stream.Application,
                                details: message.Details || null,
                                exception: message.Exception ? {
                                    type: message.Exception.Type,
                                    message: message.Exception.Message,
                                    stackTrace: message.Exception.StackTrace,
                                    innerException: message.Exception.InnerException,
                                    data: message.Exception.Data
                                } : null,
                                contextData: {
                                    transactionId: message.TransactionId,
                                    requestId: message.RequestId,
                                    connectionId: message.ConnectionId
                                }
                            });
                        }
                    } catch (error) {
                        console.warn('Error parsing error entry:', error);
                    }
                });
            }
        });

        return errors;
    }

    buildTimeline(streams) {
        const timeline = [];

        streams.forEach(stream => {
            const level = stream.stream?.level || 'info';
            const application = stream.stream?.Application || 'Unknown';

            let parsedJsonContent = {};
            if (typeof stream.stream?.Content === 'string') {
                try {

                    if (stream.stream?.Headers && stream.stream?.Headers !== '') {
                        parsedJsonContent.headers = JSON.parse(stream.stream.Headers);
                    }

                    if (stream.stream?.QueryParams && stream.stream?.QueryPatams !== '') {
                        parsedJsonContent.queryParams = JSON.parse(stream.stream.QueryParams)
                    }

                    if (stream.stream?.MessageTemplate?.startsWith('[RL {RequestId}] HTTP Request from ')) {
                        parsedJsonContent.request = JSON.parse(stream.stream?.Content);
                    } else if (stream.stream?.MessageTemplate?.startsWith('[RL {RequestId}] HTTP Response to ')) {
                        if (stream.stream?.Content === '') {
                            parsedJsonContent.response = {};
                        } else {
                            parsedJsonContent.response = JSON.parse(stream.stream?.Content);
                        }
                    } else {
                        parsedJsonContent.content = JSON.parse(stream.stream?.Content);
                    }
                } catch (err) {
                    console.warn('Failed to parse stream.stream.Content as JSON:', err);
                }
            }

            if (Object.keys(parsedJsonContent).length === 0 && typeof stream.stream?.TransactionUpdate === 'string') {
                try {
                    parsedJsonContent.transactionUpdate = JSON.parse(stream.stream.TransactionUpdate);
                } catch (err) {
                    console.warn('Failed to parse stream.stream.TransactionUpdate as JSON:', err);
                }
            }

            if (Object.keys(parsedJsonContent).length === 0 && typeof stream.stream?.AuthData === 'string') {
                try {
                    parsedJsonContent.authData = JSON.parse(stream.stream.AuthData);
                } catch (err) {
                    console.warn('Failed to parse stream.stream.AuthData as JSON:', err);
                }
            }

            if (Object.keys(parsedJsonContent).length === 0 && typeof stream.stream?.WebhookRequest === 'string') {
                try {
                    parsedJsonContent.webhookRequest = JSON.parse(stream.stream.WebhookRequest);
                    if (typeof stream.stream?.WebhookHeaders === 'string') {
                        parsedJsonContent.webhookHeaders = JSON.parse(stream.stream.WebhookHeaders);
                    }
                } catch (err) {
                    console.warn('Failed to parse stream.stream.WebhookRequest as JSON:', err);
                }
            }

            if (Object.keys(parsedJsonContent).length === 0 && typeof stream.stream?.WebhookResponse === 'string') {
                try {
                    parsedJsonContent.webhookResponse = JSON.parse(stream.stream.WebhookResponse);
                    if (typeof stream.stream?.WebhookHeaders === 'string') {
                        parsedJsonContent.webhookHeaders = JSON.parse(stream.stream.WebhookHeaders);
                    }
                } catch (err) {
                    console.warn('Failed to parse stream.stream.WebhookResponse as JSON:', err);
                }
            }

            stream.values.forEach(([timestamp, messageJson]) => {
                try {
                    const message = typeof messageJson === 'string' ? JSON.parse(messageJson) : messageJson;
                    const timeObj = new Date(parseInt(timestamp) / 1000000);

                    if (!isNaN(timeObj.getTime()) && !application.includes('UI')) {
                        timeline.push({
                            timestamp: timeObj,
                            level,
                            application,
                            message: message.Message,
                            source: message.SourceContext,
                            jsonContent: Object.keys(parsedJsonContent).length > 0 ? parsedJsonContent : null,
                            details: this.extractMessageDetails(message),
                        });
                    }
                } catch (error) {
                    console.warn('Error parsing timeline entry:', error);
                }
            });
        });

        return timeline.sort((a, b) => a.timestamp - b.timestamp);
    }

    extractMessageDetails(message) {
        const details = {};

        const fieldsToExtract = [
            'GCashState', 'GCashResult',
            'TransactionStatus', 'ElapsedMilliseconds', 'StatusCode', 'ContentType',
            'Username', 'UserIp', 'ValidationResultId', 'ValidationResultStatus', 'StoredStatus',
            'ReceivedStatus', 'ValidationEndpointUrl', 'CallbackUrl',
        ];

        fieldsToExtract.forEach(field => {
            if (message[field] !== undefined) {
                details[field] = message[field];
            }
        });

        if (message.Method && message.Uri) {
            details.request = `${message.Method} ${message.Uri}`;
        }

        return Object.keys(details).length > 0 ? details : null;
    }
}

// helper functions
export function formatDuration(ms) {
    if (ms < 1000) return `${ms}ms`;
    const seconds = Math.floor(ms / 1000);
    const minutes = Math.floor(seconds / 60);
    if (minutes > 0) {
        return `${minutes}m ${seconds % 60}s`;
    }
    return `${seconds}s`;
}
