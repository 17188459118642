import { createStore } from 'vuex';
import auth from './auth';
import providers from './providers';
import transactions from './transactions';
import merchants from './merchants';
import collabs from './collabs';
import callbacks from './callbacks';
import statistics from './statistics';
import validationEndpoints from './validationEndpoints';
import users from './users';

export default createStore({
  state: {
    isDarkTheme: localStorage.getItem('isDarkTheme') === 'true',
  },
  mutations: {
    TOGGLE_THEME(state) {
      state.isDarkTheme = !state.isDarkTheme;
      localStorage.setItem('isDarkTheme', state.isDarkTheme.toString());
    },
  },
  actions: {
    toggleTheme({ commit }) {
      commit('TOGGLE_THEME');
    },
  },
  modules: {
    auth,
    providers,
    transactions,
    merchants,
    collabs,
    callbacks,
    statistics,
    validationEndpoints,
    users,
  },
});
