<template>
  <div class="page-container">
    <div class="credentials-container">
      <div class="container-fluid">
        <h2 class="page-title">Credentials Management</h2>

        <div v-if="!isAdmin" class="access-denied">
          <i class="pi pi-lock text-danger"></i>
          <p class="text-danger">You don't have permission to view this page.</p>
        </div>

        <template v-else>
          <div class="card-container" :class="{ 'dark-theme': isDarkTheme }">
            <!-- Selection Panel -->
            <div class="selection-panel">
              <div class="selection-controls">
                <div class="dropdown-container">
                  <Dropdown id="collab-select"
                            v-if="!selectedCollabId"
                            v-model="selectedCollabId"
                            :disabled="isLoading"
                            :options="formattedCollabs"
                            optionValue="id"
                            optionLabel="label"
                            :filter="true"
                            :placeholder="isAdmin ? 'Select Collaboration' : 'Select Provider'"
                            class="w-full"
                            :class="{ 'input-dark': isDarkTheme }">
                    <template #option="slotProps">
                      <div class="dropdown-option">
                        <span v-if="isAdmin">
                          {{ slotProps.option.merchant.name }} - {{ slotProps.option.provider.name }}
                        </span>
                        <span v-else>
                          {{ slotProps.option.provider.name }}
                        </span>
                      </div>
                    </template>
                    <template #value="slotProps">
                      <div class="dropdown-value" v-if="slotProps.value">
                        <i class="pi pi-link mr-2"></i>
                        <span>{{ slotProps.option.label }}</span>
                      </div>
                      <span v-else>
                        {{ isAdmin ? 'Select Collaboration' : 'Select Provider' }}
                      </span>
                    </template>
                  </Dropdown>
                  <h3 v-else class="editor-title">
                    <i class="pi pi-key mr-2 pe-2 font-weight-bold"></i>
                    Edit {{getSelectedCollabName()}} Credentials
                  </h3>
                </div>
                <div class="action-buttons">
                  <Button label="Cancel"
                          icon="pi pi-undo"
                          @click="clearFilters"
                          class="p-button-outlined p-button-secondary" />
                  <Button label="Load Credentials"
                          icon="pi pi-search"
                          @click="loadCredentials"
                          :loading="isLoading"
                          :disabled="!selectedCollabId"
                          class="p-button-primary" />
                </div>
              </div>
            </div>

            <!-- Loading Indicator -->
            <div v-if="isLoading" class="loading-container">
              <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
              <p>Loading credentials...</p>
            </div>

            <!-- Empty State -->
            <div v-else-if="!Object.keys(credentials).length && selectedCollabId" class="empty-state">
              <i class="pi pi-info-circle"></i>
              <p>No credentials found for this collaboration</p>
            </div>

            <!-- Credentials Editor -->
            <div v-else-if="Object.keys(credentials).length" class="credentials-editor">
              <div :class="hasNestedCredentials ? '' : 'credentials-form'">
                <div 
                  v-for="(value, key) in credentials" :key="key" 
                  :class="typeof credentials[key] === 'object' && credentials[key] !== null ? '' : 'credential-field'">
                  <div v-if="typeof credentials[key] === 'object' && credentials[key] !== null" style="padding-block-end: 1rem;">
                    <div class="field-label" style="padding-block-end: 0.5rem; font-size: x-large;">
                      <label :for="key">{{ formatKeyName(key) }}</label>
                    </div>
                    <div class="credentials-form">
                      <div v-for="(subValue, subKey) in credentials[key]" :key="subKey" class="credential-field">
                        <div class="field-label">
                          <button
                              v-if="isSecretField(subKey)"
                              type="button"
                              class="toggle-visibility-btn"
                              @click="toggleSecretVisibility(key, subKey)">
                            <i :class="secretVisibility[key][subKey] ? 'pi pi-eye-slash' : 'pi pi-eye'"></i>
                          </button>
                          <label :for="subKey">{{ formatKeyName(subKey) }}</label>
                        </div>
                        <div class="field-input">
                          <InputText
                              :id="subKey"
                              v-model="credentials[key][subKey]"
                              :placeholder="`Enter ${formatKeyName(subKey)}`"
                              :type="isSecretField(subKey) && !secretVisibility[key][subKey] ? 'password' : 'text'"
                              class="w-full"
                              style="width: 100%; margin-top: 0.5rem;"
                              :class="{ 'input-dark': isDarkTheme }" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div class="field-label">
                      <button
                          v-if="isSecretField(key)"
                          type="button"
                          class="toggle-visibility-btn"
                          @click="toggleSecretVisibility(key)">
                        <i :class="secretVisibility[key] ? 'pi pi-eye-slash' : 'pi pi-eye'"></i>
                      </button>
                      <label :for="key">{{ formatKeyName(key) }}</label>
                    </div>
                    <div class="field-input">
                      <InputText
                          :id="key"
                          v-model="credentials[key]"
                          :placeholder="`Enter ${formatKeyName(key)}`"
                          :type="isSecretField(key) && !secretVisibility[key] ? 'password' : 'text'"
                          class="w-full"
                          style="width: 100%; margin-top: 0.5rem;"
                          :class="{ 'input-dark': isDarkTheme }" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="save-container">
                <Button label="Save Changes"
                        icon="pi pi-save"
                        @click="confirmSave"
                        class="p-button-success" />
              </div>
            </div>
          </div>
        </template>
      </div>

      <ConfirmDialog>
        <template #message="slotProps">
          <div class="confirm-message">
            <i :class="slotProps.message.icon" style="font-size: 1.5rem; margin-right: 0.5rem;"></i>
            <span>{{ slotProps.message.message }}</span>
          </div>
        </template>
      </ConfirmDialog>
      <ConfirmPopup></ConfirmPopup>
      <Toast />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Dropdown from 'primevue/dropdown';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Toast from 'primevue/toast';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmPopup from 'primevue/confirmpopup';
import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm";

export default {
  components: {
    Dropdown,
    Button,
    InputText,
    Toast,
    ConfirmDialog,
    ConfirmPopup
  },
  setup() {
    const toast = useToast();
    const confirm = useConfirm();
    return { confirm, toast };
  },
  name: 'CredentialsPage',
  data() {
    return {
      loadedCollabs: [],
      selectedCollabId: '',
      credentials: {},
      fieldTypes: {},
      hasNestedCredentials: false,
      secretVisibility: {},
      errorMessage: null,
      isLoading: false,
      error: null,
      search: '',
      submitted: false,
      isLoadingCollabs: false,
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('providers', ['providers']),
    ...mapState('collabs', {
      ...mapState('collabs', ['collabs']),
    }),
    isDarkTheme() {
      return this.$store.state.isDarkTheme;
    },
    isAdmin() {
      return this.user && this.user.roles.includes('Admin');
    },
    formattedCollabs() {
      return this.collabs.map(collab => ({
        ...collab,
        label: this.isAdmin
            ? `${collab.merchant.name} - ${collab.provider.name}`
            : collab.provider.name
      }));
    },
  },
  async created() {
    this.isLoadingCollabs = true;
    let response = [];
    try {
      if (this.isAdmin) {
        const providers = await this.fetchProviders();
        response = await this.fetchCollabsByProviderIds(providers.map(provider => provider.id));
      } else {
        response = await this.fetchCollabsByMerchantId(this.user.merchantId);
      }
      this.loadedCollabs = response.map(collab => ({
        id: collab.id,
        label: `${collab.merchant.name} - ${collab.provider.name}`,
      }));
    } catch (error) {
      this.error = 'Failed to load collaborations: ' + error.message;
    } finally {
      this.isLoadingCollabs = false;
    }
  },
  mounted() {
    document.title = "Credentials";
  },
  methods: {
    ...mapActions('providers', ['fetchProviders']),
    ...mapActions('collabs', [
      'fetchCollabsByMerchantId',
      'fetchCollabsByProviderIds',
      'fetchCollabCredentials',
      'updateCollabCredentials'
    ]),
    clearFilters() {
      this.selectedCollabId = '';
      this.credentials = {};
      this.secretVisibility = {};
    },
    getSelectedCollabName() {
      return this.selectedCollabId ? this.loadedCollabs.find(collab => collab.id === this.selectedCollabId).label : '';
    },
    async loadCredentials() {
      if (!this.selectedCollabId) {
        this.toast.add({ severity: 'error', summary: 'Error', detail: 'Please select a collaboration', life: 3000 });
        return;
      }
      this.isLoading = true;
      try {
        const collabId = this.selectedCollabId;
        const data = await this.fetchCollabCredentials({collabId});
        this.credentials = data || {};
        this.fieldTypes = Object.fromEntries(
            Object.entries(data).map(([key, value]) => [key, this.detectFieldType(value, key)])
        );

        // Initialize secret visibility state
        this.secretVisibility = {};
        Object.keys(this.credentials).forEach(key => {
          if (typeof this.credentials[key] === 'object' && this.credentials[key] !== null) {
            this.hasNestedCredentials = true;
            
            if (!this.secretVisibility[key]) {
              this.secretVisibility[key] = {};
            }
            
            Object.keys(this.credentials[key]).forEach(subKey => { 
              if (this.isSecretField(subKey)) {
                this.secretVisibility[key][subKey] = false;
              }
            })
          }
          else if (this.isSecretField(key)) {
            this.hasNestedCredentials = false;
            this.secretVisibility[key] = false;
          }
        });
      } catch (error) {
        this.toast.add({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 });
      } finally {
        this.isLoading = false;
      }
    },
    toggleSecretVisibility(key, subKey = null) {
      if (subKey) {
        this.secretVisibility[key][subKey] = !this.secretVisibility[key][subKey];
      }
      else {
        this.secretVisibility[key] = !this.secretVisibility[key];
      }
    },
    confirmSave() {
      this.confirm.require({
        message: 'Are you sure you want to save changes?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          await this.saveCredentials();
        }
      });
    },
    async saveCredentials() {
      try {
        const collabId = this.selectedCollabId;
        const credentials = this.prepareDataForSubmission();
        await this.updateCollabCredentials({collabId, credentials});
        this.toast.add({ severity: 'success', summary: 'Success', detail: 'Credentials saved', life: 3000 });
      } catch (error) {
        this.toast.add({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 });
      }
    },
    formatKeyName(key) {
      // Convert camelCase or snake_case to Title Case with spaces
      return key
          .replace(/_/g, ' ')
          .replace(/([A-Z])/g, ' $1')
          .replace(/^./, str => str.toUpperCase())
          .trim();
    },
    isSecretField(key) {
      const secretKeywords = ['password', 'secret', 'key', 'token', 'auth'];
      secretKeywords.some(keyword => key.toLowerCase().includes(keyword));
      return true;
    },
    prepareDataForSubmission() {
        return Object.fromEntries(
            Object.entries(this.credentials).map(([key, value]) => [
                key,
                this.fieldTypes[key] === "number" && value !== "" ? Number(value) : value
            ])
        );
    },
    detectFieldType(value, key) {
        if (typeof value === "number") {
            return "number";
        }
        if (this.isSecretField(key) && !this.secretVisibility[key]) {
            return "password";
        }
        return "text";
    },
  },
}
</script>

<style scoped>
.page-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.credentials-container {
  padding: 60px 20px 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.page-title {
  margin-bottom: 1.5rem;
  font-weight: 600;
  position: relative;
  padding-bottom: 0.5rem;
}

.page-title:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 4rem;
  height: 3px;
  background: var(--primary-color, #3B82F6);
  border-radius: 3px;
}

.card-container {
  background-color: #ffffff;
  border-radius: 1rem;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.08);
  margin-bottom: 1.5rem;
  border: 1px solid #e0e0e0;
  overflow: hidden;
  transition: all 0.3s ease;
}

.card-container.dark-theme {
  background-color: #18181B;
  color: rgb(248, 248, 248);
  border-color: #41414e;
}

.selection-panel {
  padding: 1.5rem;
  border-bottom: 1px solid #e0e0e0;
}

.dark-theme .selection-panel {
  border-bottom-color: #41414e;
}

.panel-title {
  font-size: 1.25rem;
  margin-bottom: 1rem;
  font-weight: 500;
}

.selection-controls {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: flex-end;
}

.dropdown-container {
  flex: 1;
  min-width: 300px;
}

.dropdown-container label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.dropdown-option, .dropdown-value {
  display: flex;
  align-items: center;
}

.action-buttons {
  display: flex;
  gap: 0.75rem;
}

.loading-container, .empty-state {
  padding: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #6c757d;
}

.dark-theme .loading-container,
.dark-theme .empty-state {
  color: #a0a0a0;
}

.loading-container i, .empty-state i {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  opacity: 0.7;
}

.credentials-editor {
  padding: 1.5rem;
}

.editor-title {
  font-size: 1.25rem;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.credentials-form {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.credential-field {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.field-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.field-label label {
  font-weight: 500;
  margin-bottom: 0;
}

.field-input {
  position: relative;
}

.toggle-visibility-btn {
  right: 0.75rem;
  top: 50%;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6c757d;
  z-index: 2;
  padding: 0;
  margin: 0;
}

.toggle-visibility-btn:hover {
  color: var(--primary-color, #3B82F6);
}

.dark-theme .toggle-visibility-btn {
  color: #a0a0a0;
}

:root {
  --primary-light-color: #60a5fa;
}

.dark-theme .toggle-visibility-btn:hover {
  color: var(--primary-light-color, #60a5fa);
}

.input-dark {
  background-color: #262626 !important;
  color: #e0e0e0 !important;
  border-color: #41414e !important;
}

.input-dark::placeholder {
  color: #a0a0a0 !important;
}

.save-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.access-denied {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  background-color: rgba(220, 53, 69, 0.1);
  border-radius: 0.5rem;
  margin: 2rem 0;
}

.access-denied i {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.confirm-message {
  display: flex;
  align-items: center;
}

/* Responsive styles */
@media (max-width: 768px) {
  .credentials-container {
    padding: 60px 10px 10px;
  }

  .selection-controls {
    flex-direction: column;
    align-items: stretch;
  }

  .action-buttons {
    flex-direction: row;
    justify-content: flex-end;
  }

  .credentials-form {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .selection-panel, .credentials-editor {
    padding: 1rem;
  }

  .action-buttons {
    flex-direction: column;
    width: 100%;
  }

  .action-buttons button {
    width: 100%;
  }

  .save-container {
    justify-content: center;
  }

  .save-container button {
    width: 100%;
  }
}
</style>