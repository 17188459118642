import GatewayAPI from '@/api/gateway';

const state = {
  collabs: [],
  collabSettings: null,
};

const mutations = {
  SET_COLLABS(state, collabs) {
    console.log('collabs', collabs);
    state.collabs = collabs;
  },
  SET_COLLAB_SETTINGS(state, collabSettings) {
    state.collabSettings = collabSettings;
  },
};

const actions = {
  async fetchCollabsByMerchantId({ commit }, merchantId) {
    try {
      const response = await GatewayAPI.getCollabsByMerchantId(merchantId);
      commit('SET_COLLABS', response.data);
      return response.data;
    } catch (error) {
      if (error.response.status === 500) {
        throw new Error(error.response.data.message);
      }
    }
  },
  async fetchCollabsByProviderId({ commit }, providerId) {
    try {
      const response = await GatewayAPI.getCollabsByProviderId(providerId);
      commit('SET_COLLABS', response.data);
      return response.data;
    } catch (error) {
      if (error.response.status === 500) {
        throw new Error(error.response.data.message);
      }
    }
  },
  async fetchCollabsByProviderIds({ commit }, providerIds) {
    try {
      var collabs = [];
      for (let i = 0; i < providerIds.length; i++) {
        const response = await GatewayAPI.getCollabsByProviderId(providerIds[i]);
        collabs = collabs.concat(response.data);
      }
      commit('SET_COLLABS', collabs);

      return collabs;
    } catch (error) {
      if (error.response.status === 500) {
        throw new Error(error.response.data.message);
      }
    }
  },
  async fetchCollabSettings({ commit }, collabId) {
    try {
      const response = await GatewayAPI.getCollabSettings(collabId);
      commit('SET_COLLAB_SETTINGS', response.data);
      return response.data;
    } catch (error) {
      if (error.response.status === 500) {
        throw new Error(error.response.data.message);
      }
    }
  },
  async updateCollabSettings({ commit }, { collabId, settings }) {
    try {
      await GatewayAPI.postCollabSettings(collabId, settings);
      commit('SET_COLLAB_SETTINGS', settings);
    } catch (error) {
      if (error.response.status === 500) {
        throw new Error(error.response.data.message);
      }
    }
  },
  async updateCollabActiveness(_, { collabId, isEnabled }) {
    try {
      await GatewayAPI.postCollabActiveness(collabId, isEnabled);
    } catch (error) {
      console.log(error);
      throw new Error(error.response.data.message);
    }
  },
  async fetchCollabCredentials(_, { collabId }) {
    try {
      const response = await GatewayAPI.getCredentials(collabId);
      return response.data;
    } catch (error) {

      if (error.response.status === 401 || error.response.status === 403) {
        throw new Error("You don't have permission to access this collab.");
      }

      if (error.response.status === 404) {
        //return '{"key1": "value1", "key2": "value2"}'
        throw new Error('Collab not found.');
      }

      if (error.response.status === 500) {
        throw new Error(error.response.data.message);
      }
    }
  },
  async updateCollabCredentials(_, {collabId, credentials}) {
    try {
      await GatewayAPI.setCredentials(collabId, credentials);
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};

// Path: src/store/collabs.js
