import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';
import LoginPage from '@/views/LoginPage.vue';
import DashboardPage from '@/views/DashboardPage.vue';
import ProvidersPage from '@/views/ProvidersPage.vue';
import MerchantsPage from '@/views/MerchantsPage.vue';
import TransactionsPage from '@/views/TransactionsPage.vue';
import CallbacksPage from '@/views/CallbacksPage.vue';
import ValidationEndpointsPage from '@/views/ValidationEndpointsPage.vue';
import SimulatePaymentPage from '@/views/SimulatePaymentPage.vue';
import NotFoundPage from "@/views/NotFoundPage.vue";
import AllowedIPsPage from '@/views/AllowedIPsPage.vue';
import CredentialsPage from "@/views/CredentialsPage.vue";
import BalancePage from "@/views/BalancePage.vue";

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
    meta: { requiresAuth: false, title: 'YaPay | Login'}
  },
  {
    path: '/',
    name: 'Dashboard',
    component: DashboardPage,
    meta: { requiresAuth: true, roles: ['Admin', 'Merchant Admin', 'Merchant Finance Manager'], title: 'YaPay | Dashboard' },
  },
  {
    path: '/providers',
    name: 'Providers',
    component: ProvidersPage,
    meta: { requiresAuth: true, roles: ['Admin', 'Merchant Admin', 'Merchant Finance Manager'], title: 'YaPay | Providers' },
  },
  {
    path: '/merchants',
    name: 'Merchants',
    component: MerchantsPage,
    meta: { requiresAuth: true, roles: ['Admin'], title: 'YaPay | Merchants' },
  },
  {
    path: '/transactions',
    name: 'Transactions',
    component: TransactionsPage,
    meta: { requiresAuth: true, title: 'YaPay | Transactions' },
  },
  {
    path: '/callbacks',
    name: 'Callbacks',
    component: CallbacksPage,
    meta: { requiresAuth: true, roles: ['Merchant Admin'], title: 'YaPay | Callbacks' },
  },
  {
    path: '/validation-endpoints',
    name: 'ValidationEndpoints',
    component: ValidationEndpointsPage,
    meta: { requiresAuth: true, title: 'YaPay | Validation Endpoints' },
  },
  {
    path: '/balances',
    name: 'Balances',
    component: BalancePage,
    meta: { requiresAuth: true, roles: ['Admin', 'Merchant Admin'], title: 'YaPay | Balances' },
  },
  {
    path: '/credentials',
    name: 'Credentials',
    component: CredentialsPage,
    meta: { requiresAuth: true, roles: ['Admin'], title: 'YaPay | Credentials' },
  },
  {
    path: '/allowed-ips',
    name: 'AllowedIPs',
    component: AllowedIPsPage,
    meta: { requiresAuth: true, roles: ['Admin', 'Merchant Admin'], title: 'YaPay | Allowed IPs' },
  },
  {
    path: '/simulate-payment',
    name: 'SimulatePayment',
    component: SimulatePaymentPage,
    meta: { requiresAuth: true, roles: ['Admin'], title: 'YaPay | Create Transaction' },
  },

  {
    path: '/:pathMatch(.*)*',  // this will match any unmatched path
    name: 'NotFound',
    component: NotFoundPage,
    meta: { title: 'YaPay | Not Found' }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const currentUser = store.state.auth.user;

  if (requiresAuth && !currentUser) {
    next('/login');
    return;
  }

  if (currentUser && to.meta.roles) {
    if (!currentUser.roles.some(role => to.meta.roles.includes(role))) {
      // user doesn't have required roles for this route
      // special case for root/dashboard path
      if (to.path === '/') {
        // redirect to transactions since it's available to all authenticated users
        next('/transactions');
        return;
      }

      // for other restricted routes, check if user has access to dashboard
      const dashboardRoute = routes.find(route => route.path === '/');
      const userCanAccessDashboard = currentUser.roles.some(role =>
          dashboardRoute.meta.roles.includes(role)
      );

      // redirect to dashboard if accessible, otherwise to transactions
      next(userCanAccessDashboard ? '/' : '/transactions');
      return;
    }
  }

  // update document title
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  }

  next();
});

export default router;
