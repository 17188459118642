<template>
  <div class="page-container">
    <div class="ips">
      <div class="container-fluid">
        <h2 class="mb-4">Allowed IPs</h2>
        <div v-if="!isAdmin && !user.merchantId">
          <p class="text-danger">You don't have permission to view this page.</p>
        </div>
        <template v-else>
          <div class="table-container table-responsive" :class="{ 'dark-theme': isDarkTheme }">
            <DataTable lazy v-model:editingRows="editingRows" :value="allowedIPs" dataKey="id" :loading="isLoading"
              responsiveLayout="stack" :rowHover="true" :loading-text="'Loading allowed IPs...'"
              :resizableColumns="true" style="width: 100%" editMode="row" @row-edit-save="onRowEditSave" >
              <template #header>
                <div class="table-header p-fluid">
                  <div class="p-field">
                    <Dropdown id="user-select" v-model="selectedUserId" checkmark
                      :disabled="isLoading" :options="formattedUsers" optionValue="id"
                      optionLabel="label" :filter="true" :placeholder="'Select User'"
                      class="w-full" :class="{ 'p-input-dark': isDarkTheme }">
                      <template #option="slotProps">
                        <div class="flex align-items-center">
                          {{ slotProps.option.label }}
                        </div>
                      </template>
                    </Dropdown>
                  </div>
                  <div class="p-field" style="max-width: min-content;">
                    <Button label="Clear" icon="pi pi-filter-slash" @click="clearFilters" text />
                  </div>
                  <div class="p-field" style="max-width: min-content;">
                    <Button label="Search" icon="pi pi-search" @click="fetchAllowedIPs" />
                  </div>
                  <div class="p-field" style="max-width: min-content;">
                    <Button icon="pi pi-plus" label="Add" class="p-button-success" @click="openAddDialog" />
                  </div>
                </div>
              </template>

              <template #empty>
                <div v-if="!isLoading" class="d-flex justify-content-center m-3">
                  <p>No data to be displayed</p>
                </div>
              </template>

              <Column field="id" header="ID" :headerStyle="{ position: 'sticky', top: '0' }" class="id-column">
                <template #body="{ data }">
                  <div class="id-cell">{{ data.id }}</div>
                </template>
              </Column>
              <Column field="name" header="Name">
                <template #body="{ data }">
                  <span
                      style="word-wrap: anywhere; white-space: pre-wrap; font-size: 0.9rem;">{{
                      data.name }}</span>
                </template>
                <template #editor="{ data, field }">
                  <InputText v-model="data[field]" autofocus class="w-full" />
                </template>
              </Column>
              <Column field="fromIP" header="From IP">
                <template #body="{ data }">
                  <span
                      style="word-wrap: anywhere; white-space: pre-wrap; font-size: 0.9rem;">{{
                      data.fromIP }}</span>
                </template>
                <template #editor="{ data, field }">
                  <InputText v-model="data[field]" autofocus class="w-full" />
                </template>
              </Column>
              <Column field="toIP" header="To IP">
                <template #body="{ data }">
                  <span
                      style="word-wrap: anywhere; white-space: pre-wrap; font-size: 0.9rem;">{{
                      data.toIP }}</span>
                </template>
                <template #editor="{ data, field }">
                  <InputText v-model="data[field]" autofocus class="w-full" />
                </template>
              </Column>
              <Column :rowEditor="true" style="width: 10%; min-width: 2rem"
                      bodyStyle="text-align:center"></Column>
              <Column style="width: 2rem">
                <template #body="{ data }">
                  <Button icon="pi pi-trash" class="p-button-outlined p-button-danger" @click="confirmDelete(data)" />
                </template>
              </Column>
            </DataTable>
          </div>
        </template>
      </div>

      <Dialog v-model:visible="addDialogVisible" header="Add Allowed IP" :modal="true" class="p-fluid validation-dialog"
        :maximizable="true" :style="{ width: '40rem' }" :breakpoints="{ '1199px': '50vw', '775px': '90vw' }">
        <div class="p-grid p-ai-center">
          <div class="p-col-12">
            <div class="p-inputgroup mt-3">
              <span class="p-inputgroup-addon"><i class="pi pi-sitemap"></i></span>
              <Dropdown id="userId" v-model="newAllowedIP.userId" :options="formattedUsers" optionLabel="label"
                optionValue="id" placeholder="Select User"
                :class="{ 'p-invalid': submitted && !newAllowedIP.userId }" />
            </div>
            <small class="p-error" v-if="submitted && !newAllowedIP.userId">User is required.</small>
            <div class="p-text-secondary p-mt-1">Choose user for which IP will be allowed.</div>
          </div>
          <div class="p-col-12">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon"><i class="pi pi-link"></i></span>
              <InputText id="name" v-model="newAllowedIP.name" placeholder="Name"
                :class="{ 'p-invalid': submitted && !newAllowedIP.name }" />
            </div>
            <small class="p-error" v-if="submitted && !newAllowedIP.name">Allowed IP name is required.</small>
            <div class="p-text-secondary">Enter the name for server or device of this IP range.</div>
          </div>
          <div class="p-col-12">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon"><i class="pi pi-link"></i></span>
              <InputText id="fromIP" v-model="newAllowedIP.fromIP" placeholder="From IP"
                :class="{ 'p-invalid': submitted && !newAllowedIP.fromIP }" />
            </div>
            <small class="p-error" v-if="submitted && !newAllowedIP.fromIP">Allowed IP range beginning is required.</small>
            <div class="p-text-secondary">Enter the beginning of this IP range.</div>
          </div>
          <div class="p-col-12">
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon"><i class="pi pi-link"></i></span>
              <InputText id="toIP" v-model="newAllowedIP.toIP" placeholder="To IP"
                :class="{ 'p-invalid': submitted && !newAllowedIP.toIP }" />
            </div>
            <small class="p-error" v-if="submitted && !newAllowedIP.toIP">Allowed IP range end is required.</small>
            <div class="p-text-secondary">Enter the end of this IP range.</div>
          </div>
        </div>
        <template #footer>
          <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="addDialogVisible = false" />
          <Button label="Save" icon="pi pi-check" class="p-button-success" @click="saveNewAllowedIP" />
        </template>
      </Dialog>

      <ConfirmDialog>
        <template #message="slotProps">
          <div class="flex align-items-center">
            <i :class="slotProps.message.icon" style="font-size: 1.5rem; margin-right: 0.5rem;"></i>
            <span>{{ slotProps.message.message }}</span>
          </div>
        </template>
      </ConfirmDialog>
      <ConfirmPopup></ConfirmPopup>
      <Toast />
    </div>
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dropdown from 'primevue/dropdown';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Dialog from 'primevue/dialog';
import Toast from 'primevue/toast';
import { useToast } from "primevue/usetoast";
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmPopup from 'primevue/confirmpopup';
import { useConfirm } from "primevue/useconfirm";

export default {
  components: {
    DataTable,
    Column,
    Dropdown,
    Button,
    InputText,
    Dialog,
    Toast,
    ConfirmDialog,
    ConfirmPopup,
  },
  setup() {
    const toast = useToast();
    const confirm = useConfirm();
    return { confirm, toast };
  },
  name: 'AllowedIPsPage',
  data() {
    return {
      selectedUserId: '',
      editingRows: [],
      allowedIPs: [],
      errorMessage: null,
      isLoading: false,
      isLoadingValidations: false,
      error: null,
      addDialogVisible: false,
      submitted: false,
      search: '',
      newAllowedIP: this.initNewAllowedIP(),
    };
  },
  computed: {
    ...mapState('auth', ['user']),

    ...mapState('providers', ['providers']),
    ...mapState('users', ['users']),
    ...mapState('collabs', {
      ...mapState('collabs', ['collabs']),
    }),
    isDarkTheme() {
      return this.$store.state.isDarkTheme;
    },
    isAdmin() {
      return this.user && this.user.roles.includes('Admin');
    },
    formattedUsers() {
      return (this.users || []).map(user => ({
        id: user.id,
        label: user.username
      }));
    },
  },
  async created() {
    this.$store.dispatch('users/fetchUsers');
  },
  watch: {
    users(newVal) {
      console.log('Users updated in watch:', newVal);
    }
  },
  mounted() {
  },
  methods: {
    ...mapActions('providers', ['fetchProviders']),
    ...mapActions('collabs', [
      'fetchCollabsByMerchantId',
      'fetchCollabsByProviderIds']),
    ...mapActions('users', [
      'fetchUsers',
      'fetchAllowedIPsByUserId',
      'createAllowedIP',
      'updateAllowedIP',
      'deleteAllowedIP']),

    initNewAllowedIP() {
      return {
        name: '',
        userId: null,
        fromIP: '',
        toIP: ''
      }
    },

    clearFilters() {
      this.selectedUserId = '';
    },

    async fetchAllowedIPs() {
      try {
        this.error = null;
        this.isLoading = true;

        if (this.selectedUserId) {
          const response = await this.fetchAllowedIPsByUserId(this.selectedUserId);
          this.allowedIPs = response;
        }
      } catch (error) {
        this.error = 'Failed to fetch allowed IPs. Please try again.';
        this.toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to fetch allowed IPs: ' + error.message, life: 5500 });
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },

    openAddDialog() {
      this.newAllowedIP = this.initNewAllowedIP();
      this.submitted = false;
      this.addDialogVisible = true;
    },

      async onRowEditSave(event) {
      const { newData, index } = event;

      if (newData.id) {
        this.confirm.require({
          message: 'Are you sure you want to update this allowed IP?',
          header: 'Confirmation',
          acceptIcon: 'pi pi-check',
          rejectIcon: 'pi pi-times',
          acceptClass: 'p-button-warning',
          accept: async () => {
            try {
              this.toast.add({ severity: 'info', summary: 'Updating...', detail: 'Updating allowed IP', life: 3000 });
              const changedAllowedIP = await this.updateAllowedIP({ userId: this.selectedUserId, allowedIPId: newData.id, allowedIP: newData });
              this.toast.add({ severity: 'success', summary: 'Successful', detail: 'Allowed IP updated', life: 3000 });
              this.allowedIPs[index] = changedAllowedIP;
            } catch (error) {
              this.toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to update allowed IP: ' + error.message, life: 3000 });
            }
          },
          reject: () => {
            // Revert changes
            this.allowedIPs[index] = { ...this.allowedIPs[index], ...event.data };
          }
        });
      } else {
        this.confirm.require({
          message: 'Are you sure you want to create a new allowed IP?',
          header: 'Confirmation',
          acceptIcon: 'pi pi-check',
          rejectIcon: 'pi pi-times',
          acceptClass: 'p-button-success',
          accept: async () => {
            try {
              this.toast.add({ severity: 'info', summary: 'Adding...', detail: 'Adding new allowed IP', life: 3000 });
              const createdAllowedIP = await this.createAllowedIP({ userId: this.selectedUserId, allowedIP: newData });
              this.allowedIPs[index] = createdAllowedIP;
              this.toast.add({ severity: 'success', summary: 'Successful', detail: 'Allowed IP created', life: 3000 });
            } catch (error) {
              this.toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to create allowed IP: ' + error.message, life: 3000 });
            }
          },
          reject: () => {
            // Remove the new row
            this.allowedIPs = this.allowedIPs.filter(endpoint => endpoint.id > 0);
          }
        });
      }
    },

    async saveNewAllowedIP() {
      this.submitted = true;

      if (this.newAllowedIP.name && this.newAllowedIP.fromIP && this.newAllowedIP.toIP) {
        this.confirm.require({
          message: 'Are you sure you want to create a new allowed IP?',
          header: 'Confirmation',
          acceptIcon: 'pi pi-check',
          rejectIcon: 'pi pi-times',
          acceptClass: 'p-button-success',
          accept: async () => {
            try {
              this.toast.add({ severity: 'info', summary: 'Adding...', detail: 'Adding new allowed IP', life: 3000 });
              const createdAllowedIP = await this.createAllowedIP({ userId: this.selectedUserId, allowedIP: this.newAllowedIP });
              this.allowedIPs.unshift(createdAllowedIP);
              this.toast.add({ severity: 'success', summary: 'Successful', detail: 'Allowed IP created', life: 3000 });
              this.addDialogVisible = false;
            } catch (error) {
              this.toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to create allowed IP: ' + error.message, life: 3000 });
            }
          },
        });
      }
    },

    confirmDelete(allowedIP) {
      this.confirm.require({
        message: 'Are you sure you want to delete this allowed IP?',
        header: 'Delete Confirmation',
        icon: 'pi pi-info-circle',
        acceptIcon: 'pi pi-trash',
        rejectIcon: 'pi pi-times',
        acceptClass: 'p-button-danger',
        accept: async () => {
          try {
            this.toast.add({ severity: 'info', summary: 'Deleting...', detail: 'Deleting allowed IP', life: 3000 });
            await this.deleteAllowedIP({ userId: this.selectedUserId, allowedIPId: allowedIP.id });
            this.allowedIPs = this.allowedIPs.filter(url => url.id !== allowedIP.id);
            this.toast.add({ severity: 'success', summary: 'Successful', detail: 'Allowed IP deleted', life: 3000 });
          } catch (error) {
            this.toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to delete allowed IP: ' + error.message, life: 3000 });
          }
        }
      });
    },
  },
}

</script>

<style scoped>

.page-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.ips {
  padding: 60px 10px 10px;
  display: flex;
  flex-direction: column;
}

.transaction-details pre {
  overflow-x: auto;
  max-height: 300px;
}

.table-container {
  background-color: #ffffff;
  border-radius: 1rem;
  padding: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 1.2rem;
  border: 2px solid #e0e0e0;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
}

.table-container.dark-theme {
  background-color: #18181B;
  color: rgb(248, 248, 248);
  border-color: #41414e;
}

.table-responsive {
  flex: 1;
  overflow: auto;
}

.table-responsive .p-datatable-thead th,
.table-responsive .p-datatable-tbody td {
  white-space: nowrap;
  border: none !important;
}

.table-header {
  display: flex;
  flex-wrap: wrap;
  gap: 0.6rem;
}

.table-header .p-field {
  flex: 1 1 450px;
}

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.table-responsive {
  flex: 1;
  overflow: hidden;
}

.table-responsive .p-datatable-thead th,
.table-responsive .p-datatable-tbody td {
  white-space: nowrap;
  border: none !important;
}

@media (max-width: 600px) {

  table {
    font-size: 12px;
  }

  .transactions-table .p-datatable-thead th,
  .transactions-table .p-datatable-tbody td {
    white-space: nowrap;
  }

  .table-responsive {
    overflow-x: auto;
  }

  .table-responsive .p-datatable-thead th,
  .table-responsive .p-datatable-tbody td {
    white-space: nowrap;
  }

  .table-header .p-field {
    flex: 1 1 100%;
  }

  .table-header,
  .table-container {
    padding: 0.5rem;
  }
}
</style>