import GatewayAPI from '@/api/gateway';

const state = {
  users: [],
}

const mutations = {
  SET_USERS(state, users) {
    console.log('Mutation SET_USERS:', users);
    state.users = users;
  },
  SET_ALLOWED_IPS(state, allowedIPs) {
      state.allowedIPs = allowedIPs;
  },
};

const actions = {
  async fetchUsers({ commit }) {
    try {
      const response = await GatewayAPI.getUsers();
      commit('SET_USERS', response.data);
      return response.data;
    } catch (error) {
      if (error.response.status === 500) {
        throw new Error(error.response.data.message);
      }
    }
  },

  async fetchAllowedIPsByUserId({ commit }, userId) {
    try {
      const response = await GatewayAPI.getAllowedIPsByUserId(userId);
      commit('SET_ALLOWED_IPS', response.data);
      return response.data;
    } catch (error) {
      if (error.response.status === 500) {
        throw new Error(error.response.data.message);
      }
    }
  },

  async createAllowedIP({ dispatch }, { userId, allowedIP }) {
    try {
      const response = await GatewayAPI.createAllowedIP(allowedIP);
      await dispatch('fetchAllowedIPsByUserId', userId);
      return response.data;
    } catch (error) {
      if (error.response.status === 500) {
        throw new Error(error.response.data.message);
      }
    }
  },

  async updateAllowedIP({ dispatch }, { userId, allowedIPId, allowedIP }) {
    try {
      const response = await GatewayAPI.updateAllowedIP(allowedIPId, allowedIP);
      await dispatch('fetchAllowedIPsByUserId', userId);
      return response.data;
    } catch (error) {
      if (error.response.status === 500) {
        throw new Error(error.response.data.message);
      }
    }
  },

  async deleteAllowedIP({ dispatch }, { userId, allowedIPId }) {
    try {
      const response = await GatewayAPI.deleteAllowedIP(allowedIPId);
      await dispatch('fetchAllowedIPsByUserId', userId);
      return response.data;
    } catch (error) {
      if (error.response.status === 500) {
        throw new Error(error.response.data.message);
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};